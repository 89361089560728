<template>
  <v-toolbar
    color="white"
    dense
    class="rounded"
  >
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tooltip
      color="secondary"
      bottom
    >
      <template #activator="{ on, attrs}">
        <v-chip
          v-show="total !== null"
          v-bind="attrs"
          class="mr-1"
          v-on="on"
        >
          <v-avatar left>
            <v-icon>{{ icons.mdiInformation }}</v-icon>
          </v-avatar>
          <span class="font-weight-bold">{{ total }}</span>
        </v-chip>
      </template>
      <span>Total</span>
    </v-tooltip>

    <v-tooltip
      color="secondary"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          fab
          color="secondary"
          v-bind="attrs"
          icon
          elevation="9"
          outlined
          small
          class="mr-1"
          @click="refreshElts"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-btn>
      </template>
      <span>Raffraichir</span>
    </v-tooltip>
    <v-tooltip
      color="danger"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          fab
          color="danger"
          v-bind="attrs"
          icon
          elevation="9"
          outlined
          class="mr-1"
          small
          @click.stop="back"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ icons.mdiArrowLeftBoldCircleOutline }}
          </v-icon>
        </v-btn>
      </template>
      <span>Précédent</span>
    </v-tooltip>
    <v-tooltip
      color="primary"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-show="add !== null"
          small
          color="primary"
          class="mb-4 me-3 mt-4"
          v-bind="attrs"
          icon
          elevation="9"
          outlined
          @click.stop="addElt()"
          v-on="on"
        >
          <v-icon
            dense
          >
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ add }}</span>
    </v-tooltip>
    <v-menu

      left
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="notification === true"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item
            v-for="(n, index) in filters"
            :key="index"
            @click="filterList(n.val)"
          >
            <v-list-item-title>{{ n.label }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import {
  mdiRefresh, mdiArrowLeftBoldCircleOutline, mdiPlus, mdiDotsVertical, mdiInformation,
} from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api/dist/vue-composition-api'
import router from '@/router'
import store from '@/store'

export default {
  name: 'CustomToolbar',
  emits: ['refresh', 'add', 'filterList'],

  props: {
    title: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
    add: {
      type: String,
      default: null,
    },
    notification: {
      type: Boolean,
      default: false,
    },
  },

  setup({ emit }) {
    const selectedItem = ref(0)
    const instance = getCurrentInstance()
    const refreshElts = page => {
      instance.emit('refresh', page)
    }

    const addElt = () => {
      instance.emit('add')
    }

    const back = () => {
      router.back()
    }

    const filterList = val => {
      instance.emit('filterList', val)
    }

    return {
      back,
      filterList,
      refreshElts,
      addElt,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
        mdiPlus,
        mdiDotsVertical,
        mdiInformation,
      },
      filters: [
        { label: 'Toutes', val: null },
        { label: 'Non lues', val: false },
        { label: 'Lues', val: true },
      ],
      selectedItem,
    }
  },
}
</script>

<style scoped>

</style>
